





























































































import { Vue, Component, Prop, Watch } from 'vue-class-decorator'
import { GlobalModule } from '@/store'
import { Payload } from 'vue/interfaces'
import { File } from 'vue/types'

@Component({})
export default class ImageCarouselComponent extends Vue {
  @Prop({ default: '' }) readonly title!: string
  @Prop({
    default: () => {
      return []
    },
    required: true
  }) value!: Array<File>

  public readonly h2: string
  public count: number
  public imageArrayForCarousel: Array<{ [propName: string]: any } | null>
  public imageArrayForServer: Array<File>
  public selectedImageInArray: number
  public sliderKey: boolean
  public globalKey: boolean
  public propFiles: Array<File>

  constructor() {
    super()
    this.sliderKey = false
    this.globalKey = false
    this.h2 = this.title
    this.count = 0
    this.selectedImageInArray = 0
    this.imageArrayForCarousel = []
    this.imageArrayForServer = []
    this.propFiles = this.value
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @GlobalModule.Action('loadImage') loadImage!: (image: Payload) => Promise<any>

  @Watch('count') renderComponentByCount() {
    this.sliderKey = !this.sliderKey
  }

  @Watch('value') renderComponentByFiles() {
    if (this.propFiles.length === 0) {
      this.propFiles = this.value
      this.fillServerImagesArray()
    }
    this.globalKey = !this.globalKey
  }

  @Watch('imageArrayForCarousel') async uploudImage() {
    if (this.imageArrayForCarousel[this.selectedImageInArray] !== null && this.imageArrayForCarousel[this.selectedImageInArray]?.size < 2000000) {
      const image = this.imageArrayForCarousel[this.selectedImageInArray] as Payload
      this.loadImage(image)
        .then((res: any) => {
          this.imageArrayForServer[this.selectedImageInArray] = res.files[0]
          this.sliderKey = !this.sliderKey
          this.handleInput()
        })
        .catch((error: any) => {
          this.$noty('error', error.message, 7000).show()
        })
    }
  }

  handleInput() {
    const prepareArray: Array<File> = []
    this.imageArrayForServer.forEach((item: File) => {
      if (item.file_id) {
        prepareArray.push(item)
      }
    })
    this.$emit('input', prepareArray)
  }

  deleteImageFromServer(n: number) {
    this.imageArrayForCarousel.splice(n, 1)
    this.imageArrayForServer.splice(n, 1)
    this.handleInput()
  }

  openFileLoadModal(n: number) {
    const input = document.getElementById(`image-carousel-fi-${n}`)
    if (input) {
      input.click()
      this.selectedImageInArray = n
    }
  }

  fillServerImagesArray() {
    if (this.propFiles.length > 0) {
      this.propFiles.forEach((item, index) => {
        this.imageArrayForServer[index + 1] = item
      })
      this.count = this.imageArrayForServer.length - 1
      this.handleInput()
    }
  }

  created() {
    this.fillServerImagesArray()
  }
}
